body{
    padding: 0 !important;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    display: none; /* This will hide the scrollbar in WebKit browsers */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    filter: blur(4px);
    transform: translate(-50%, -50%)
}

.spinner div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #000000;
    border-radius: 50%;
    animation: spinner 1.2s linear infinite;
}

.spinner div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}

.spinner div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}

.spinner div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}

.spinner div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}

.spinner div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}

.spinner div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}

.spinner div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}

.spinner div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}

.spinner div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}

.spinner div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}

.spinner div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}

.spinner div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}

@keyframes spinner {
    0%, 20%, 80%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}
.slick-list {
    min-height: 350px !important; /* Adjust based on your slide content */
}
/* Remove default Slick dot styles since you're using custom dots */
.slick-dots li button:before {
    display: none; /* Hide Slick's default dots */
}

/* Ensure active dot is fully white */
.slick-dots li.slick-active .slick-dot {
    background-color: #ffffff;
    transform: scale(1.1);
    opacity: 1;
}

/* Style for non-active dots */
.slick-dots li .slick-dot {
    background-color: rgba(255, 255, 255, 0.5);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Hover effect for better UX */
.slick-dots li .slick-dot:hover {
    background-color: rgba(255, 255, 255, 0.8);
    transform: scale(1.1);
}
