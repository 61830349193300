@font-face {
  font-family: 'Avenir Next Condensed Regular';
  src: url('./AvenirNextCondensed-Regular-08.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Canela Light';
  src: url('./Canela-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Canela Regular';
  src: url('./Canela-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
